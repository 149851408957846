import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import workStyles from "./modules/WorkPage.module.css";

function WorkPage() {

  return (
    <div>

      <div>
        <Navbar />
      </div>

      <div className={workStyles.main}>

        <div>
          <span><h1>Work with Future<text style={{color: "#38B6FF"}}>X</text></h1></span>
        </div>

        <div style={{ marginTop: "18px"}}>
          <span>
            <h2>
              Thank you for your interest to work with us.<br />We can assist with research, end-to-end solutions, and data-driven community insights to propel your project to new heights. 🚀 We are rooting for your success, assisting top-tier projects with research in zk, infra, gaming, and NFTs. 🌟
            </h2>
          </span>
        </div>

        <div style={{marginTop: "18px"}}>
          <div>
            <h2>
              <span >How we can assist:</span>
              <br /><br />
              <div className={workStyles.assist}>
                <span>🎯 Problem Identification :&ensp;Collaborate with us to pinpoint and comprehend the core issue your project aims to tackle in the web3 ecosystem</span>
                <br /><br />
                <span>🔍 In-depth Market Analysis :&ensp;Leverage comprehensive market research to assess opportunities and the competitive landscape in your niche</span>
                <br /><br />
                <span>🗺️ Stakeholder Mapping :&ensp;Discover your project's key stakeholders, grasp their motivations, and design tailored incentives to maintain engagement</span>
                <br /><br />
                <span>🏆 Product Value & Design :&ensp;Craft a captivating product offering by defining its utility and developing an enticing architecture</span>
                <br /><br />
                <span>🌱 Business Expansion & Networking :&ensp;Connect with fellow web3 enthusiasts to foster growth, achieve mutual goals, and unlock new opportunities</span>
                <br /><br />
                <span>💰 Funding Solutions :&ensp;Explore funding avenues such as venture capital, token launches, or NFTs with our guidance</span>
                <br /><br />
                <span>🤝 Community & Culture Nurturing :&ensp;Will assist in creating vibrant communities that enhance brand visibility and cultivate a positive environment</span>
                <br /><br />
                <span>🚀 GTM Planning & Launch :&ensp;Strategize the optimal go-to-market approach, pinpoint your target audience, and make compelling offerings</span>
              </div>
            </h2>
          </div>

          <div style={{marginTop: "40px"}}>
            <span><h2>References <big>🔖</big></h2></span>
            <ul className={workStyles.backgroundWork}>
              <li><a href="https://futurex.club/discover?domain=All" target="_blank">FutureX Newsletter</a></li>
              <li><a target="_blank">Product Discovery Tool</a></li>
            </ul>
          </div>
        </div>

        <div style={{marginTop: "25px"}}>
          <span><h2>You can learn all about our background & the work we've done <big>👇🏼</big></h2></span>
          <ul className={workStyles.backgroundWork}>
            <li><a href="https://futurex.club/" target="_blank">FutureX</a></li>
            <li><a href="https://darshang.substack.com/" target="_blank">Research / deep-dives</a></li>
            <li><a href="https://twitter.com/FutureXClub" target="_blank">Twitter</a></li>
            <li><a href="https://www.linkedin.com/company/futurexclub/" target="_blank">LinkedIn</a></li>
          </ul>
        </div>

        <hr style={{width: "100%", marginTop: "30px", marginBottom: "20px"}}/>

        <div>
          <div>
            <span><h2>We work on an hourly-basis payout model, at&ensp;<code style={{color: "#38B6FF", fontSize: "24px"}}>$100/Hour</code>.</h2></span>
            <span><h2>If you are aligned with the ask, find it exciting & want assistance across any of the sections mentioned above, please feel free to reach out via <a href="mailto:connect.futurex@gmail.com" target="_blank">email</a>.</h2></span>
          </div>

          <div style={{lineHeight: "37px"}}>
            <span>
              <h2>
                PS: Please note that any information or opinions we share should not be considered financial, tax, or legal advice. Exercise your judgment when making decisions. My contributions are solely based on my personal views in a one-on-one setting and do not represent any company or firm. Additionally, we are unable to assist you with connections at organizations we are affiliated with.
              </h2>
            </span>
          </div>
          
        </div>

      </div>

      <div>
          <Footer />
      </div>

    </div>

    
  )
}

export default WorkPage
