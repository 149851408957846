import React from 'react'
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import aboutStyles from "./modules/AboutUsPage.module.css";

function AboutUsPage() {
  return (
    <div>
        <div>
            <Navbar />
        </div>
        
        <div style={{
            marginTop: "50px",
            marginBottom: "40px",
            display: "flex",
            justifyContent: "center",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center"
            }}>
                <img src="darshan-aboutus.jpg" style={{borderRadius: "500px", width: "220px"}} />

                <div style={{marginLeft: "-20px"}}><h1>Darshan Gandhi</h1></div>

                <div className={aboutStyles.allLinkLogos} style={{marginLeft: "25px"}}>
                    <a href="https://twitter.com/DarshanG_" target="_blank">
                    <img src="/twitter-logo.png" className={aboutStyles.linkLogos} alt="Twitter Logo" />
                    </a>
                    <a href="https://www.linkedin.com/in/gandhi-darshan/" target="_blank">
                    <img src="/linkedin-logo.png" className={aboutStyles.linkLogos} alt="Linkedin Logo"/>
                    </a>
                    <a href="mailto:connect.futurex@gmail.com" style={{marginTop: "-11px", fontSize: "2.4em"}} target="_blank">
                    ✉️
                    </a>
              </div>
            </div>
        </div>

        <div>
            <Footer />
        </div>

    </div>
  )
}

export default AboutUsPage
